<template>
  <div class="owners-edit">
    <UserSearch v-if="editable" :title="title || ''" @submit="addOwner" />
    <div class="owners-edit-owners">
      <component
        :is="clickable ? 'router-link' : 'div'"
        v-for="owner in owners"
        :key="owner.uuid"
        :to="ownerPage"
      >
        <div class="owners-edit-owners-owner">
          <Avatar :user="owner" />
          <p class="owners-edit-owners-owner-name">
            {{ $umodel.full_name(owner) }}
          </p>
          <Button
            v-if="editable"
            icon="close"
            type="white"
            size="xs"
            class="owners-edit-owners-owner-remove"
            @click="() => removeOwner(owner)"
          />
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import UserSearch from '@/views-v2/users/UserSearch.vue'
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'ORIOwnersEdit',
  components: { UserSearch, Button, Avatar },
  props: {
    item: {
      type: Object,
      default: () => undefined
    },
    defaultOwners: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    owners: []
  }),
  created() {
    this.owners = JSON.parse(JSON.stringify(this.item?.owners || [])).filter(
      (owner) => !owner.is_inherited
    )
    if (!this.owners.length && this.defaultOwners?.length)
      this.defaultOwners.forEach((owner) => this.addOwner(owner))
  },
  methods: {
    addOwner(user) {
      if (this.owners.find((owner) => owner.uuid === user.uuid)) return
      this.owners.push(user)
      this.$emit('submit', this.owners)
    },
    removeOwner(user) {
      this.owners = this.owners.filter((owner) => owner.uuid !== user.uuid)
      this.$emit('submit', this.owners)
    }
  }
}
</script>

<style lang="scss" scoped>
.owners-edit {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &-owners {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 1rem;

    &-owner {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px solid rgba(#000, 8%);
      border-radius: 8px;
      transition: box-shadow 0.2s ease;

      &:hover {
        box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
      }

      &-name {
        font-weight: 600;
      }

      &-remove {
        margin-left: auto;
      }
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
