<template>
  <div>
    <InlineSearch
      placeholder="Search for users..."
      :title="title"
      :search-function="searchUsers"
      v-on="$listeners"
    >
      <template #item="{ item }">
        <div class="user-search-result">
          <Avatar :user="item" />
          <p class="user-search-result-name">
            {{ $umodel.full_name(item) }}
          </p>
        </div>
      </template>
    </InlineSearch>
  </div>
</template>

<script>
import Avatar from '@c/library/Avatar.vue'
import InlineSearch from '@c/library/InlineSearch.vue'
import { mapActions } from 'vuex'

export default {
  name: 'UserSearch',
  components: { InlineSearch, Avatar },
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions(['getWorkspaceMembersPage']),
    async searchUsers(query) {
      try {
        const members = await this.getWorkspaceMembersPage({
          workspace_id: this.$route.params.workspace_id,
          params: {
            search: query
          },
          page_size: this.pageSize
        })
        if ((members?.length || 0) !== 0) {
          return members
        }
        return []
      } catch (e) {
        this.$console.debug(
          'Something went wrong when loading user search results',
          e
        )
        this.$toast.error(e, 'loading user search results')
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-search {
  &-result {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    border: 1px solid rgba(#000, 8%);
    border-radius: 8px;
    transition: box-shadow 0.2s ease;
    width: 100%;

    &:hover {
      box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
    }

    &-name {
      font-weight: 600;
    }
  }
}
</style>
