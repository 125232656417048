var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "owners-edit" },
    [
      _vm.editable
        ? _c("UserSearch", {
            attrs: { title: _vm.title || "" },
            on: { submit: _vm.addOwner }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "owners-edit-owners" },
        _vm._l(_vm.owners, function(owner) {
          return _c(
            _vm.clickable ? "router-link" : "div",
            { key: owner.uuid, tag: "component", attrs: { to: _vm.ownerPage } },
            [
              _c(
                "div",
                { staticClass: "owners-edit-owners-owner" },
                [
                  _c("Avatar", { attrs: { user: owner } }),
                  _c("p", { staticClass: "owners-edit-owners-owner-name" }, [
                    _vm._v(" " + _vm._s(_vm.$umodel.full_name(owner)) + " ")
                  ]),
                  _vm.editable
                    ? _c("Button", {
                        staticClass: "owners-edit-owners-owner-remove",
                        attrs: { icon: "close", type: "white", size: "xs" },
                        on: {
                          click: function() {
                            return _vm.removeOwner(owner)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }