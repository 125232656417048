var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "InlineSearch",
        _vm._g(
          {
            attrs: {
              placeholder: "Search for users...",
              title: _vm.title,
              "search-function": _vm.searchUsers
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "user-search-result" },
                      [
                        _c("Avatar", { attrs: { user: item } }),
                        _c("p", { staticClass: "user-search-result-name" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$umodel.full_name(item)) + " "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }